import styled from "styled-components";
import { useState } from "react";
import { ChatMessage } from "./utils/requestHandler";
import RatingButton from "./RatingButton";

const RATINGS = [1, 2, 3, 4, 5];

const Container = styled.div`
  margin-top: -5px;
  margin-bottom: 2rem;
`;

const RatingsWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
const Text = styled.div`
  font-size: 10px;
  color: gray;
  margin-bottom: 3px;
`;

function AnswerRating({ message }: { message: ChatMessage }) {
  const [selectedRating, setSelectedRating] = useState<number | null>(null);
  return (
    <Container>
      <Text>{"Rate this answer from 1 (bad) to 5 (great)"}</Text>
      <RatingsWrapper>
        {RATINGS.map((rating, i) =>
          !!message.processId ? (
            <RatingButton
              key={i}
              processId={message.processId}
              rating={rating}
              selectedRating={selectedRating}
              setSelectedRating={setSelectedRating}
            />
          ) : (
            <></>
          )
        )}
      </RatingsWrapper>
    </Container>
  );
}

export default AnswerRating;
