import styled from "styled-components";
import { sendAnswerRating } from "./utils/requestHandler";
import { Dispatch, SetStateAction } from "react";

const Button = styled.button<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border-width: 0px;
  margin-right: 10px;
  padding: 8px;
  font-size: 10px;
  cursor: pointer;
  color: ${(props) => (props.isSelected ? "white" : "black")};
  background-color: ${(props) => (props.isSelected ? "black" : "white")};
  border: ${(props) =>
    props.isSelected ? " 1px solid white" : " 1px solid black"};

  &:hover {
    color: white;
    background-color: black;
    border: 1px solid white;
  }
`;

function RatingButton({
  processId,
  rating,
  selectedRating,
  setSelectedRating,
}: {
  processId: string;
  rating: number;
  selectedRating: number | null;
  setSelectedRating: Dispatch<SetStateAction<number | null>>;
}) {
  return (
    <Button
      isSelected={selectedRating === rating}
      onClick={() => {
        sendAnswerRating(processId, rating);
        setSelectedRating(rating);
        console.log({ selectedRating, rating });
      }}
    >
      {rating}
    </Button>
  );
}

export default RatingButton;
