import styled from "styled-components";
import { useContext } from "react";
import { ChatContext } from "./context/chatContext";
import { textToFontSize } from "./utils/utils";
import { ChatMessageRole } from "./utils/requestHandler";
import AnswerRating from "./AnswerRating";

const QuestionAnswerWrapper = styled.div`
  width: 100%;
`;

const Message = styled.div<{
  role: ChatMessageRole;
  fontSize: string;
}>`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) =>
    props.role === ChatMessageRole.USER ? "flex-end" : "flex-start"};
  text-align: ${(props) =>
    props.role === ChatMessageRole.USER ? "end" : "start"};
  font-size: ${(props) => props.fontSize};
  align-self: flex-end;
  margin: ${(props) =>
    props.role === ChatMessageRole.USER ? "0 0 3rem 5rem" : "0 5rem 1rem 0"};
  color: ${(props) => (props.role === ChatMessageRole.USER ? "gray" : "black")};
`;

const Image = styled.img`
  width: 100%;
  max-width: 800px;
  border: 1px solid black;
  margin-top: 10px;
`;

function Messages() {
  const { messages } = useContext(ChatContext);

  return (
    <>
      {messages
        .sort((a, b) =>
          a.created_at_in_unix_seconds > b.created_at_in_unix_seconds ? 1 : -1
        )
        .map((message, i) => (
          <QuestionAnswerWrapper key={i}>
            <Message
              role={message.role}
              fontSize={textToFontSize(message.content.text)}
            >
              {message.content.text}
              {message.content.b64_encoded_images.length > 0 &&
                message.content.b64_encoded_images.map((b64_image) => (
                  <Image src={`data:image/png;base64,${b64_image}`}></Image>
                ))}
            </Message>
            {message.role === ChatMessageRole.AGENT && (
              <AnswerRating message={message} />
            )}
          </QuestionAnswerWrapper>
        ))}
    </>
  );
}

export default Messages;
