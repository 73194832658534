const API_KEY_LOCAL_STORAGE_NAME = "api-key";

export const textToFontSize = (text: string): string => {
  const magicConstant = 268;
  const textLength = text.length;
  let fontSize = magicConstant / Math.sqrt(textLength);

  const finalSize =
    fontSize > 30 ? 30 : fontSize < 18 ? 18 : Math.floor(fontSize);
  return `${finalSize}px`;
};

export const get_unix_in_seconds_now = (): number => {
  return Math.round(new Date().getTime() / 1000);
};

export const get_api_key = (): string | null => {
  try {
    const apiKey = JSON.parse(
      localStorage.getItem(API_KEY_LOCAL_STORAGE_NAME) || ""
    );
    return apiKey || null;
  } catch (error) {
    return null;
  }
};

export const set_api_key = (apiKey: string) => {
  localStorage.setItem(API_KEY_LOCAL_STORAGE_NAME, JSON.stringify(apiKey));
};
