import styled, { css } from "styled-components";
import Logo from "./icons/Logo";
import { useContext, useEffect, useState } from "react";
import { ChatContext } from "./context/chatContext";
import ArrowRight from "./icons/ArrowRight";
import {
  ChatMessage,
  ChatMessageContent,
  ChatMessageRequestModel,
  ChatMessageRole,
  getChatMessageStatus,
  sendChatMessage,
} from "./utils/requestHandler";
import { get_unix_in_seconds_now } from "./utils/utils";
import Messages from "./Messages";
import ActivityGif from "./ActivityGif";
import ApiKeyField from "./ApiKeyField";
import { ApiKeyContext } from "./context/apiKeyContext";
import CorrelationIdField from "./CorrelationIdField";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5rem;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 10rem;
  width: 100;
`;
const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
`;

const OpacityFadeWrapper = styled.div<{ enableOpacity: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 10rem;
  width: 600;
  ${(props) =>
    props.enableOpacity &&
    css`
      -webkit-mask-image: linear-gradient(to top, black 50%, transparent 110%);
      mask-image: linear-gradient(to top, black 50%, transparent 110%);
      -ms-overflow-style: none;
      scrollbar-width: none;
    `}
`;

const InputField = styled.input`
  width: 500px;
  height: 40px;
  border-width: 0px;
  font-size: large;
  outline: none;
`;

const InputFieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 2rem 0 2rem 0;
  width: 90%;
`;

const ProcessingStatusWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
`;

const ProcessStatus = styled.div`
  font-size: medium;
  font-style: italic;
`;

const ThoughtWrapper = styled.div`
  margin-top: 15px;
`;

const ErrorHeader = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: darkred;
  margin-bottom: 1rem;
`;

const ErrorText = styled.div`
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
`;

const ThoughtTitle = styled.div`
  font-style: normal;
  font-size: 14px;
  font-weight: 700;
`;
const Thought = styled.div`
  font-style: italic;
  margin-top: 10px;
  font-size: 14px;
`;

const SendButton = styled.button`
  background-color: #ffffff00;
  border-width: 0px;
  cursor: pointer;
`;

function Main() {
  const {
    messages,
    setMessages,
    isPolling,
    setIsPolling,
    activeProcessId,
    setActiveProcessId,
    activeThreadId,
    setActiveThreadId,
    processStatus,
    setProcessStatus,
    currentThoughts,
    setCurrentThoughts,
    activeCorrelationId,
    setActiveCorrelationId,
  } = useContext(ChatContext);

  const { apiKey } = useContext(ApiKeyContext);

  const [activeMessage, setActiveMessage] = useState("");

  const [error, setError] = useState<string>("");

  const sendMessage = async () => {
    if (!activeMessage) return;
    const newMessageContent: ChatMessageContent = {
      text: activeMessage,
      b64_encoded_images: [],
    };
    const newUserMessage: ChatMessage = {
      role: ChatMessageRole.USER,
      content: newMessageContent,
      created_at_in_unix_seconds: get_unix_in_seconds_now(),
    };
    const newMessages = messages.concat([newUserMessage]);
    setMessages(newMessages);
    setActiveMessage("");

    const requestModel: ChatMessageRequestModel = {
      messages: newMessages,
      thread_id: activeThreadId || undefined,
    };

    const response = await sendChatMessage(requestModel);
    if (typeof response === "string") {
      setError(response);
      return;
    }

    setActiveThreadId(response.thread_id ? response.thread_id : null);

    if (response.process_id) {
      setActiveProcessId(response.process_id);
      setIsPolling(true);
    }

    if (response.correlationId) {
      setActiveCorrelationId(response.correlationId);
    }
  };

  function delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  useEffect(() => {
    const startPolling = async (processId: string) => {
      let continuePolling = true;
      let inLoopCurrentThoughts: string[] = currentThoughts;

      while (continuePolling) {
        const statusResponse = await getChatMessageStatus(processId);
        if (statusResponse.status === "DONE") {
          const newAgentMessage: ChatMessage = {
            role: ChatMessageRole.AGENT,
            ...statusResponse.result,
            processId,
          };
          const newMessages = messages.concat([newAgentMessage]);
          setActiveThreadId(statusResponse?.result?.thread_id || null);
          setMessages(newMessages);
          continuePolling = false;
        } else if (["CANCELING", "CANCELLED"].includes(statusResponse.status)) {
          continuePolling = false;
        } else if (statusResponse.status === "FAILED") {
          continuePolling = false;
          const message = `Recieved status FAILED from polling on process_id ${processId}`;
          setError(message);
        } else if (
          ["PROCESSING", "NOT_STARTED"].includes(statusResponse.status)
        ) {
          const statusString =
            statusResponse.status === "PROCESSING"
              ? statusResponse.processing_step_type
              : statusResponse.status;
          setProcessStatus(statusString);

          const newCurrentThoughts = inLoopCurrentThoughts.includes(
            statusResponse.thought
          )
            ? inLoopCurrentThoughts
            : !!statusResponse.thought
            ? [...inLoopCurrentThoughts, statusResponse.thought]
            : [...inLoopCurrentThoughts];

          inLoopCurrentThoughts = newCurrentThoughts;
          setCurrentThoughts(newCurrentThoughts);

          await delay(4000);
        } else {
          continuePolling = false;
          setIsPolling(false);
          setProcessStatus(null);
          setCurrentThoughts([]);
          const message = `New status received! ${statusResponse.status}`;
          setError(message);
          return;
        }
      }
      setIsPolling(false);
      setActiveProcessId(null);
      setProcessStatus(null);
      setCurrentThoughts([]);
    };

    if (activeProcessId) startPolling(activeProcessId);
  }, [activeProcessId]);

  if (error) {
    return (
      <Container>
        <Wrapper>
          <ErrorHeader>{"Something went wrong!"}</ErrorHeader>
          <ErrorText>
            {"Send the following info to violet@maurten.com"}
          </ErrorText>
          <ErrorText>{"---------------------------------"}</ErrorText>
          <ErrorText>{`error message: ${error}`}</ErrorText>
          <ErrorText>{`processId: ${activeProcessId}`}</ErrorText>
          <ErrorText>{`correlationId: ${activeCorrelationId}`}</ErrorText>
          <ErrorText>{"---------------------------------"}</ErrorText>
          <ErrorText>{"Reload page to try again"}</ErrorText>
        </Wrapper>
      </Container>
    );
  }

  return (
    <>
      <ButtonsWrapper>
        <ApiKeyField />
        <CorrelationIdField />
      </ButtonsWrapper>
      {apiKey && (
        <Container>
          <Wrapper>
            <Logo color={"#000"} width={100} />
          </Wrapper>
          <OpacityFadeWrapper enableOpacity={messages.length > 3}>
            <Messages />
            <InputFieldWrapper>
              <InputField
                placeholder="Type here"
                value={activeMessage}
                onChange={(e) => setActiveMessage(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") sendMessage();
                }}
              />
              <SendButton onClick={() => sendMessage()}>
                <ArrowRight color="#000" width={40} />
              </SendButton>
            </InputFieldWrapper>
            <ProcessingStatusWrapper>
              {isPolling && <ActivityGif />}
              {processStatus && (
                <ProcessStatus>{processStatus}...</ProcessStatus>
              )}
              <br />
            </ProcessingStatusWrapper>
            {processStatus && !!currentThoughts.length && (
              <ThoughtWrapper>
                <ThoughtTitle>{"AI thought process"}</ThoughtTitle>
                {currentThoughts.map((thought, i) => (
                  <Thought key={i}>{thought}</Thought>
                ))}
              </ThoughtWrapper>
            )}
          </OpacityFadeWrapper>
        </Container>
      )}
    </>
  );
}

export default Main;
