import { useContext, useState } from "react";
import styled from "styled-components";
import { ChatContext } from "./context/chatContext";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const Button = styled.button`
  background-color: #ffffff00;
  border: 1px solid black;
  border-radius: 2px;
  margin-right: 5px;
  cursor: pointer;
`;

const CorrelationString = styled.div`
  width: fit-content;
  font-size: 14px;
  margin-bottom: 5px;
`;

const CorrelationIdField = () => {
  const { activeCorrelationId } = useContext(ChatContext);

  const [show, setShow] = useState<boolean>(false);

  return (
    <>
      {!show ? (
        <Wrapper>
          <Button onClick={() => setShow(true)}>{"Show correlation id"}</Button>
        </Wrapper>
      ) : (
        <Wrapper>
          <CorrelationString>
            {activeCorrelationId
              ? `Latest correlation id: ${activeCorrelationId}`
              : "Send a message to get a correlation id"}
          </CorrelationString>
          <ButtonsWrapper>
            <Button onClick={() => setShow(false)}>{"Hide"}</Button>
          </ButtonsWrapper>
        </Wrapper>
      )}
    </>
  );
};

export default CorrelationIdField;
