import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useState,
} from "react";
import { ChatMessage } from "../utils/requestHandler";

export interface ChatContextProps {
  messages: ChatMessage[];
  setMessages: Dispatch<SetStateAction<ChatMessage[]>>;
  isPolling: boolean;
  setIsPolling: Dispatch<SetStateAction<boolean>>;
  activeProcessId: string | null;
  setActiveProcessId: Dispatch<SetStateAction<string | null>>;
  activeThreadId: string | null;
  setActiveThreadId: Dispatch<SetStateAction<string | null>>;
  activeCorrelationId: string | null;
  setActiveCorrelationId: Dispatch<SetStateAction<string | null>>;
  processStatus: string | null;
  setProcessStatus: Dispatch<SetStateAction<string | null>>;
  currentThoughts: string[];
  setCurrentThoughts: Dispatch<SetStateAction<string[]>>;
}

export const ChatContext = createContext<ChatContextProps>(
  {} as ChatContextProps
);

const ChatContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [isPolling, setIsPolling] = useState<boolean>(false);
  const [activeProcessId, setActiveProcessId] = useState<string | null>(null);
  const [activeThreadId, setActiveThreadId] = useState<string | null>(null);
  const [activeCorrelationId, setActiveCorrelationId] = useState<string | null>(
    null
  );
  const [processStatus, setProcessStatus] = useState<string | null>(null);
  const [currentThoughts, setCurrentThoughts] = useState<string[]>([]);

  return (
    <ChatContext.Provider
      value={{
        messages,
        setMessages,
        isPolling,
        setIsPolling,
        activeProcessId,
        setActiveProcessId,
        activeThreadId,
        setActiveThreadId,
        activeCorrelationId,
        setActiveCorrelationId,
        processStatus,
        setProcessStatus,
        currentThoughts,
        setCurrentThoughts,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export default ChatContextProvider;
