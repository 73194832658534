import * as env from "env-var";
import { get_api_key } from "./utils";

const AGENT_API_URL: string = env
  .get("REACT_APP_AGENT_API_URL")
  .required()
  .asString();

const customFetch = async (
  endpoint: string,
  apiMethod: "POST" | "GET" | "PUT" | "DELETE",
  body?: any
): Promise<Response> => {
  const url = `${AGENT_API_URL}${endpoint}`;
  const apiKey = get_api_key();
  const headers = {
    "Content-Type": "application/json",
    "x-api-key": apiKey || "",
  };
  return await fetch(url, {
    method: apiMethod,
    headers,
    body: JSON.stringify(body),
  });
};

const makeApiCall = async <T>(
  endpoint: string,
  apiMethod: "POST" | "GET" | "PUT" | "DELETE",
  shouldFail: boolean = false,
  body?: any
) => {
  return await customFetch(endpoint, apiMethod, body)
    .then(async (response) => {
      if (shouldFail) {
        throw new Error(`Intenden error on ${endpoint}`);
      }
      let correlationId =
        response.headers.get("X-Correlation-Id") ||
        response.headers.get("x-correlation-id");

      if (response.ok) {
        const result = await response.json();
        result.correlationId = correlationId;
        return result;
      }

      return response.status === 422
        ? `Incorrect request body to ${endpoint} (422 Unprocessable Entity) - either an FE error or the API endpoint has been updated`
        : `Got error on ${endpoint} with status ${response.status}`;
    })
    .catch((error) => {
      console.log(`Error on call to ${endpoint}.`, { error });
      return "Cannot connect to API - it seems to be down! - Make sure it is up and running!";
    });
};

export interface ChatMessageContent {
  text: string;
  b64_encoded_images: string[];
}

export enum ChatMessageRole {
  USER = "user",
  AGENT = "assistant",
}

export interface ChatMessage {
  role: ChatMessageRole;
  created_at_in_unix_seconds: number;
  content: ChatMessageContent;
  processId?: string;
}

export interface ChatMessageRequestModel {
  messages: ChatMessage[];
  thread_id?: string;
}
export interface ChatMessageResponseModel {
  process_id: string;
  thread_id?: string;
  started_at_in_unix_seconds: number;
  correlationId?: string;
}

export const sendChatMessage = async (
  body: ChatMessageRequestModel
): Promise<ChatMessageResponseModel | string> => {
  if (body.messages.length > 20) {
    body.messages = body.messages.slice(body.messages.length - 20);
  }
  const response = await makeApiCall("/v0/en/chat", "POST", false, body);
  return response;
};

export const getChatMessageStatus = async (processId: string): Promise<any> => {
  const response = await makeApiCall(`/v0/chat/${processId}`, "GET");
  return response;
};

export const sendAnswerRating = async (
  processId: string,
  rating: number
): Promise<any> => {
  const response = await makeApiCall(
    `/v0/chat/${processId}/rate-answer`,
    "POST",
    false,
    { rating }
  );
  return response;
};
