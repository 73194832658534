import { useContext, useState } from "react";
import styled from "styled-components";
import { ApiKeyContext } from "./context/apiKeyContext";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const Button = styled.button`
  background-color: #ffffff00;
  border: 1px solid black;
  border-radius: 2px;
  margin-right: 5px;
  cursor: pointer;
`;

const InputField = styled.input`
  width: 500px;
  font-size: 14px;
  margin-bottom: 5px;
`;

const ApiKeyField = () => {
  const { apiKey, setApiKey } = useContext(ApiKeyContext);
  const [activeKey, setActiveKey] = useState(apiKey);
  const [oldKey, setOldKey] = useState("");

  return (
    <>
      {apiKey ? (
        <Wrapper>
          <Button
            onClick={() => {
              setOldKey(apiKey);
              setActiveKey(apiKey);
              setApiKey("");
            }}
          >
            {"Edit key"}
          </Button>
        </Wrapper>
      ) : (
        <Wrapper>
          <InputField
            placeholder="Input your key here!"
            value={activeKey || ""}
            onChange={(e) => setActiveKey(e.target.value.trim())}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setApiKey(activeKey || "");
                window.location.reload();
              }
            }}
          />
          <ButtonsWrapper>
            <Button
              onClick={() => {
                setApiKey(activeKey || "");
                window.location.reload();
              }}
            >
              {"Add"}
            </Button>
            {oldKey && (
              <Button
                onClick={() => {
                  setApiKey(oldKey);
                  setOldKey("");
                }}
              >
                {"Cancel"}
              </Button>
            )}
          </ButtonsWrapper>
        </Wrapper>
      )}
    </>
  );
};

export default ApiKeyField;
