const Logo = ({ color, width }: { color: string; width: number }) => {
  return (
    <svg
      color={color}
      id="uktbzveoqa-Lager_1"
      className="header-logo"
      width={width}
      height={(45 / 50) * width}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 400 300"
    >
      <path d="M38.47,296.6a19.63,19.63,0,0,1-19.25-20h0L20.55,94.77,178.7,227.22l120.75-225,60.13,206.2a19.92,19.92,0,0,1-13,24.7,19.31,19.31,0,0,1-24.14-13.31L288.81,104.5,190.22,288.16,58.75,178,58,276.9a19.6,19.6,0,0,1-19.52,19.7Z"></path>
      <ellipse cx="360.72" cy="277.22" rx="20.06" ry="20.53"></ellipse>
    </svg>
  );
};

export default Logo;
