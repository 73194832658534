import { createContext, FC, PropsWithChildren, useState } from "react";
import { get_api_key, set_api_key } from "../utils/utils";

export interface ApiKeyContextProps {
  apiKey: string | null;
  setApiKey: (key: string) => void;
}

export const ApiKeyContext = createContext<ApiKeyContextProps>(
  {} as ApiKeyContextProps
);

const ApiKeyContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [apiKey, stateSetApiKey] = useState<string | null>(get_api_key());

  const setApiKey = (apiKey: string) => {
    set_api_key(apiKey);
    stateSetApiKey(apiKey);
  };

  return (
    <ApiKeyContext.Provider
      value={{
        apiKey,
        setApiKey,
      }}
    >
      {children}
    </ApiKeyContext.Provider>
  );
};

export default ApiKeyContextProvider;
